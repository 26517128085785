import { useCallback } from "react";
import { useSelector } from "react-redux";
import getMandatoryEnv from "sites-common/utils/getMandatoryEnv";
import { selectorSrcmToken } from "../../state/selectors";

const useFetchOmsApi = ({ provider } = { provider: "kanhaOMS" }) => {
  const domainName = getMandatoryEnv([provider])[provider];
  const srcmtoken = useSelector(selectorSrcmToken);

  const fetchOmsApi = useCallback(
    ({ api, method = "GET", methodParams = {}, retHandler = "json" }) => {
      const innerProps = {
        method,
        headers: {
          Authorization: `Bearer ${srcmtoken.token}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(methodParams),
      };
      if (method === "GET") {
        delete innerProps.body;
      }
      return fetch(`${domainName}${api}`, innerProps)
        .then((res) => {
          if (!res.ok) {
            return res.json().then((text) => {
              throw new Error(text);
            });
          }
          if (retHandler === "json") {
            return res.json();
          }
          if (retHandler === "text") {
            return res.text();
          }
          return res.json();
        })
        .catch(() => {});
    },
    [domainName, srcmtoken.token]
  );

  return { fetchOmsApi };
};

export default useFetchOmsApi;
