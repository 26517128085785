/** @jsx jsx */
import { jsx } from "theme-ui";
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { get, orderBy } from "lodash";
import PropTypes from "prop-types";
import Loader from "gatsby-plugin-hfn-profile/components/ui/Loader";
import useFetchOmsApi from "gatsby-plugin-hfn-profile/components/Hooks/useFetchOmsApi";
import getFormattedDate from "sites-common/utils/FormatDate";
import { upperFirst } from "sites-common/utils/lodash";
import {
  DefaultButton,
  Dialog,
  DialogType,
  IconButton,
} from "office-ui-fabric-react";
import handleError from "sites-common/utils/handleError";

import { showAlert } from "gatsby-plugin-hfn-profile/state/actions";
import FileSaver from "file-saver";
import { useSelector } from "react-redux";
import { selectorSrcmProfile } from "gatsby-plugin-hfn-profile/state/selectors";
import { appsDialogStyles } from "../utils/dialogStyles";

const modelProps = {
  isBlocking: false,
  styles: { main: { maxWidth: "max-content !important" } },
  containerClassName: "container_modal_payment",
};

const headings = ["Date", "Receipt Number", "Download"];

const PaymentTable = ({ orders, isFetching }) => {
  const [sort, setSort] = useState({});
  const [displayTable, SetDisplayTable] = useState({});
  const [hideDialog, setHideDialog] = useState({});
  const [hideDialogBox, setHideDialogBox] = useState({});
  const [responseData, setResponseData] = useState([]);
  const { fetchOmsApi } = useFetchOmsApi({ provider: "paymentService" });
  const { fetchOmsApi: fetchOmsApiKanha } = useFetchOmsApi({
    provider: "kanhaOMS",
  });
  const [paymentData, setPaymentData] = useState({});
  const [showDialog, setShowDialog] = useState(null);
  const paymentDownCont = [undefined, "PAYMENT_FAILED"];
  const dialogContentProps = useMemo(
    () => ({
      type: DialogType.largeHeader,
      title: `${hideDialog?.refund ? "Refund" : "Payment"} Order (${
        hideDialog?.id
      })`,
    }),
    [hideDialog]
  );
  const dialogContentPropsErrorReason = useMemo(
    () => ({
      type: DialogType.largeHeader,
    }),
    []
  );

  const srcmProfile = useSelector(selectorSrcmProfile);
  let rolesList;
  if (srcmProfile && srcmProfile.roles) {
    rolesList = [...srcmProfile.roles];
  }
  useEffect(() => {
    const ids =
      Object.entries(displayTable) &&
      Object.entries(displayTable).length > 0 &&
      Object.entries(displayTable).map(([k, v]) =>
        v && !paymentData[k] ? k : false
      );

    if (ids && ids.length > 0) {
      ids.map((item) => {
        const paymentOrder = orders.find(
          (item2) => item2.id === item
        )?.paymentOrderId;
        if (paymentOrder) {
          fetchOmsApi({
            api: `/payment-order-details/${paymentOrder}`,
          })
            .then((e) => {
              setPaymentData((prev) => ({ ...prev, [item]: e }));
            })
            .catch(() => {});
        }
        return null;
      });
    }
  }, [displayTable, orders, fetchOmsApi, setPaymentData, paymentData]);

  const paymentFailureReason = (refId) => {
    if (refId) {
      fetchOmsApi({
        api: `/payment-order-details/${refId}`,
      })
        .then((e) => {
          setResponseData(e?.payload);
          setHideDialogBox();
        })
        .catch(() => {});
    }
    return null;
  };
  // useEffect (() => {
  //   paymentFailureReason();
  // },[])

  const sortedOrder = useMemo(() => {
    const newOrder = [...orders];
    if (sort?.id) {
      newOrder.sort((a, b) => b.id - a.id);
    }
    if (sort?.status) {
      newOrder.sort((x, y) => {
        if (x.paymentStatus < y.paymentStatus) {
          return -1;
        }
        if (y.paymentStatus > x.paymentStatus) {
          return 1;
        }
        return 0;
      });
    }

    return newOrder;
  }, [sort, orders]);

  const displayStatus = useCallback((status) => {
    if (status) {
      const statusData = status.toLowerCase().split("_")[1];
      return upperFirst(statusData);
    }
    return "-";
  }, []);

  const displayRefundTotal = useCallback((refund) => {
    if (!refund) {
      return "-";
    }
    const refundAmout = refund.reduce((a, b) => a + b.amount, 0);
    return Number(refundAmout).toFixed(2);
  }, []);

  const displayDownload = (payments) => {
    let count = 0;
    if (payments && payments.length > 0) {
      const payStatus = payments.map((item) => item.status);
      payStatus.map((a) => {
        if (!paymentDownCont.includes(a)) {
          count += 1;
        }
        return a;
      });
    }
    return count;
  };

  const getAndDownloadFile = useCallback(async (url, fileName) => {
    try {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          FileSaver.saveAs(blobUrl, fileName);
        })
        .catch((e) => {
          showAlert({
            title: "Error",
            message: handleError(e),
            confirm_text: "Okay",
            is_blocking: true,
          });
        });
    } catch {
      showAlert({
        title: "Error",
        message: `Something went wrong. Please try again later.`,
        confirm_text: "Okay",
        is_blocking: true,
      });
    }
  }, []);

  const downloadReceipt = useCallback(
    (oid, fileName, path) => {
      fetchOmsApiKanha({
        api: `${path}${oid}`,
        method: "GET",
      })
        .then((res) => {
          if (res && res.receiptPdfSignedUrl) {
            return getAndDownloadFile(res.receiptPdfSignedUrl, fileName);
          }
          return showAlert({
            title: "Error",
            message: handleError(res),
            confirm_text: "Okay",
            is_blocking: true,
          });
        })
        .catch((e) =>
          showAlert({
            title: "Error",
            message: handleError(e),
            confirm_text: "Okay",
            is_blocking: true,
          })
        );
    },
    [fetchOmsApiKanha, getAndDownloadFile]
  );

  const downloadReceiptAndPopup = useCallback(
    (oid, path, callbackreceipt) => {
      fetchOmsApiKanha({
        api: `${path}${oid}`,
        method: "GET",
      })
        .then((res) => {
          if (res && res.receipts && res.receipts.length > 1) {
            return callbackreceipt(res.receipts);
          }
          if (res && res.receipts && res.receipts.length === 1) {
            return downloadReceipt(
              res.receipts[0].id,
              `Receipt-${oid}`,
              "/download/receipt/"
            );
          }

          return showAlert({
            title: "Error",
            message: handleError(res),
            confirm_text: "Okay",
            is_blocking: true,
          });
        })
        .catch((e) =>
          showAlert({
            title: "Error",
            message: handleError(e),
            confirm_text: "Okay",
            is_blocking: true,
          })
        );
    },
    [fetchOmsApiKanha, downloadReceipt]
  );

  return (
    <>
      <div
        sx={{ width: "100%", overflowX: "scroll", scrollbarWidth: "thin" }}
        variant="paymentTable"
      >
        {isFetching && (
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "40px 0",
              svg: {
                mt: "8px",
                mr: "4px",
              },
            }}
          >
            <Loader type="Oval" color="grey" width={30} height={25} />
            Loading..
          </div>
        )}
        {sortedOrder && !isFetching && sortedOrder.length === 0 && (
          <p
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: "40px 0",
              fontWeight: 600,
            }}
          >
            No records found.
          </p>
        )}
        {sortedOrder && sortedOrder.length > 0 && !isFetching && (
          <table cellSpacing={0} sx={{ width: "100%" }}>
            <thead
              sx={{
                background: "#dfdfff",
                p: "0.25rem",
                td: {
                  fontWeight: 500,
                  paddingRight: "24px",
                  whiteSpace: "nowrap",
                  padding: "12px 20px",
                  fontSize: "0.75rem",
                },
              }}
            >
              <tr>
                <td />

                <td>Date</td>
                <td sx={{ display: "flex" }}>
                  Order Id{" "}
                  <span
                    className="sort_icon"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() =>
                      setSort((prev) => ({ ...prev, id: !prev.id }))
                    }
                    onClick={() =>
                      setSort((prev) => ({ ...prev, id: !prev.id }))
                    }
                    sx={{
                      width: 0,
                      height: 0,
                      border: "solid 8px",
                      borderColor: "transparent transparent grey transparent",
                      transform: "rotate(180deg)",
                      alignSelf: "center",
                      marginLeft: "0.5rem",
                      marginTop: "4px",
                      cursor: "pointer",
                      ...(sort.id
                        ? {
                            transform: "rotate(0deg)",
                            marginTop: "0",
                            marginBottom: "4px",
                          }
                        : {}),
                    }}
                  >
                    &nbsp;
                  </span>
                </td>
                <td>Abhyasi Id</td>
                <td>Name</td>
                <td>Payment</td>
                <td>Paid</td>
                <td>Refund</td>
                <td sx={{ display: "flex" }}>
                  Order Status
                  <span
                    className="sort_icon"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() =>
                      setSort((prev) => ({ ...prev, status: !prev.status }))
                    }
                    onClick={() =>
                      setSort((prev) => ({ ...prev, status: !prev.status }))
                    }
                    sx={{
                      width: 0,
                      height: 0,
                      border: "solid 8px",
                      borderColor: "transparent transparent grey transparent",
                      transform: "rotate(180deg)",
                      alignSelf: "center",
                      marginLeft: "0.5rem",
                      marginTop: "4px",
                      cursor: "pointer",
                      ...(sort.status
                        ? {
                            transform: "rotate(0deg)",
                            marginTop: "0",
                            marginBottom: "4px",
                          }
                        : {}),
                    }}
                  >
                    &nbsp;
                  </span>
                </td>
                <td>GST Status</td>
                <td>Email</td>
                <td>Contact</td>
                {rolesList.includes("info_center") && (
                  <td>Receipt / Invoice</td>
                )}
              </tr>
            </thead>
            <tbody>
              {sortedOrder.map((item) => (
                <React.Fragment key={item.id}>
                  <tr
                    sx={{
                      td: {
                        padding: "8px",
                        textIndent: "12px",
                        whiteSpace: "nowrap",
                        fontSize: "0.675rem",
                        borderBottom: "1px solid #dfdfff",
                      },
                      "&:hover": {
                        background: "#dfdfff42",
                      },
                    }}
                  >
                    <td sx={{ cursor: "pointer" }}>
                      <span
                        role="button"
                        onClick={() =>
                          SetDisplayTable((prev) => ({
                            ...prev,
                            [item.id]: !prev[item.id],
                          }))
                        }
                        onKeyDown={() =>
                          SetDisplayTable((prev) => ({
                            ...prev,
                            [item.id]: !prev[item.id],
                          }))
                        }
                        tabIndex={0}
                        sx={{
                          paddingRight: "8px",
                          border: "1px solid",
                          display: "block",
                        }}
                      >
                        {displayTable[item.id] ? "-" : "+"}
                      </span>
                    </td>
                    <td>
                      {getFormattedDate(
                        get(item, "createdDate", "-"),
                        "Date-Time"
                      )}
                    </td>
                    <td>{get(item, "id", "-")}</td>
                    <td>{get(item, "buyer.ref", "-")}</td>
                    <td>{get(item, "buyer.name", "-")}</td>
                    <td sx={{ cursor: "pointer", color: "#00a70f" }}>
                      <span tabIndex={0} role="button">
                        {displayRefundTotal(item?.refunds) !== "-"
                          ? (
                              Number(get(item, "total", "0")) +
                              Number(displayRefundTotal(item?.refunds))
                            )?.toFixed(2)
                          : Number(get(item, "total", "0"))?.toFixed(2)}
                      </span>
                    </td>
                    <td sx={{ cursor: "pointer", color: "#00a70f" }}>
                      <span
                        tabIndex={0}
                        role="button"
                        onKeyDown={() =>
                          setHideDialog({
                            id: item.id,
                            payments: item?.payments,
                            name: get(item, "buyer.name", "-"),
                          })
                        }
                        onClick={() =>
                          setHideDialog({
                            id: item.id,
                            payments: item?.payments,
                            name: get(item, "buyer.name", "-"),
                          })
                        }
                      >
                        {Number(get(item, "total", "0"))?.toFixed(2)}
                      </span>
                    </td>
                    <td
                      sx={
                        displayRefundTotal(item?.refunds) !== "-"
                          ? { cursor: "pointer", color: "#c50400" }
                          : {}
                      }
                    >
                      <span
                        tabIndex={0}
                        role="button"
                        onKeyDown={() => {
                          if (displayRefundTotal(item?.refunds) !== "-") {
                            return setHideDialog({
                              id: item.id,
                              payments: item?.refunds,
                              name: get(item, "buyer.name", "-"),
                              refund: true,
                            });
                          }
                          return {};
                        }}
                        onClick={() => {
                          if (displayRefundTotal(item?.refunds) !== "-") {
                            return setHideDialog({
                              id: item.id,
                              payments: item?.refunds,
                              name: get(item, "buyer.name", "-"),
                              refund: true,
                            });
                          }
                          return {};
                        }}
                      >
                        {displayRefundTotal(item?.refunds)}
                      </span>
                    </td>
                    <td>
                      <span>
                        {displayStatus(get(item, "status", ""))}
                        {item?.status === "ORDER_FAILED" ? (
                          <span
                            sx={{
                              cursor: "pointer",
                              color: "red",
                              fontWeight: 900,
                              marginLeft: "4px",
                            }}
                            onKeyDown={() => {
                              paymentFailureReason(
                                item?.payments[0]?.referenceId
                              );
                            }}
                            onClick={() => {
                              paymentFailureReason(
                                item?.payments[0]?.referenceId
                              );
                            }}
                            type="button"
                            tabIndex={0}
                            role="button"
                          >
                            ?
                          </span>
                        ) : (
                          " "
                        )}
                      </span>
                    </td>
                    <td>
                      {get(item, "hasPendingAmount", false)
                        ? "Pending"
                        : "Paid"}
                    </td>
                    <td>{get(item, "buyer.email", "-")}</td>
                    <td>{get(item, "buyer.phone", "-")}</td>
                    {rolesList.includes("info_center") && (
                      <td className="download_icon">
                        {displayDownload(item?.payments) ? (
                          <>
                            <IconButton
                              iconProps={{ iconName: "Download" }}
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                downloadReceiptAndPopup(
                                  item.id,
                                  "/receipts/",
                                  (data) => setShowDialog({ data, id: item.id })
                                )
                              }
                              title="Receipt"
                            />
                            <IconButton
                              iconProps={{ iconName: "Download" }}
                              sx={{ cursor: "pointer" }}
                              title="Inovice"
                              onClick={() =>
                                downloadReceipt(
                                  item.id,
                                  `invoice-${item.id}`,
                                  "/download/invoice/"
                                )
                              }
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                    )}
                  </tr>
                  {displayTable[item.id] && (
                    <React.Fragment>
                      {paymentData[item.id] ? (
                        <React.Fragment>
                          {get(paymentData[item.id], "receipt_number", "") ? (
                            <tr
                              sx={{
                                background: "#f0e9f6",
                                td: {
                                  padding: "4px 8px",
                                  textIndent: "12px",
                                  fontSize: "0.675rem",
                                },
                              }}
                            >
                              <td colSpan={12}>
                                Reciept:{" "}
                                {get(
                                  paymentData[item.id],
                                  "receipt_number",
                                  "-"
                                )}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {get(
                            paymentData[item.id],
                            "payload.payment_error_reason",
                            ""
                          ) ? (
                            <tr
                              sx={{
                                background: "#f0e9f6",
                                td: {
                                  padding: "4px 8px",
                                  textIndent: "12px",
                                  fontSize: "0.675rem",
                                },
                              }}
                            >
                              <td colSpan={12}>
                                Reason:{" "}
                                {get(
                                  paymentData[item.id],
                                  "payload.payment_error_reason",
                                  "-"
                                )}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      <tr sx={{ mb: "4px" }}>
                        <td colSpan={14}>
                          <table
                            cellSpacing={0}
                            sx={{
                              width: "100%",
                            }}
                          >
                            <thead
                              sx={{
                                background: "#495a89",
                                color: "#fff",
                                p: "0.25rem",
                                td: {
                                  fontWeight: 500,
                                  paddingRight: "24px",
                                  whiteSpace: "nowrap",
                                  padding: "12px 20px",
                                  fontSize: "0.75rem",
                                },
                              }}
                            >
                              <tr>
                                <td>Item Order ID</td>
                                <td>Abhyasi ID</td>
                                <td>Name</td>
                                <td>Dorm Option</td>
                                <td>Berth Option</td>
                                <td>Email</td>
                                <td>Phone</td>
                                <td>Status</td>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.items?.map((elem) => (
                                <tr
                                  key={elem.id}
                                  sx={{
                                    background: "#f0e9f6",
                                    td: {
                                      padding: "4px 8px",
                                      textIndent: "12px",
                                      fontSize: "0.675rem",
                                      borderBottom: "1px solid #dfdfff",
                                    },
                                  }}
                                >
                                  <td>{get(elem, "id", "-")}</td>
                                  <td>{get(elem, "details.ref", "-")}</td>
                                  <td
                                    sx={
                                      displayStatus(get(item, "status")) ===
                                      "Cancelled"
                                        ? { textDecoration: "line-through" }
                                        : {}
                                    }
                                  >
                                    {get(elem, "details.name", "-")}
                                  </td>
                                  <td
                                    sx={
                                      displayStatus(get(item, "status")) ===
                                      "Cancelled"
                                        ? { textDecoration: "line-through" }
                                        : {}
                                    }
                                  >
                                    {get(elem, "details.dorm", "-")}
                                  </td>
                                  <td>{get(elem, "details.berth", "-")}</td>
                                  <td>{get(elem, "details.email", "-")}</td>
                                  <td>{get(elem, "details.mobile", "-")}</td>
                                  <td>
                                    {displayStatus(get(elem, "status", ""))}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
        <Dialog
          hidden={!hideDialog?.id}
          onDismiss={() => setHideDialog({})}
          dialogContentProps={dialogContentProps}
          modalProps={modelProps}
        >
          <table cellSpacing={0}>
            <thead
              sx={{
                background: "#dfdfff",
                p: "0.25rem",
                td: {
                  fontWeight: 500,
                  paddingRight: "24px",
                  whiteSpace: "nowrap",
                  padding: "12px 20px",
                  fontSize: "0.75rem",
                },
              }}
            >
              <tr sx={{ td: { width: "max-content" } }}>
                <td>Date</td>
                <td>Name</td>
                <td>Payment</td>
                <td>Order Reference ID</td>
                <td>Payment Reference ID</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {hideDialog?.payments?.map((py) => (
                <tr
                  key={py.referenceId}
                  sx={{
                    td: {
                      padding: "8px",
                      textIndent: "12px",
                      whiteSpace: "nowrap",
                      fontSize: "0.675rem",
                      borderBottom: "1px solid #dfdfff",
                    },
                    "&:hover": {
                      background: "#dfdfff42",
                    },
                  }}
                >
                  <td>
                    {" "}
                    {getFormattedDate(get(py, "createdDate", "-"), "Date-Time")}
                  </td>
                  <td>{hideDialog?.name}</td>
                  <td>{Number(get(py, "amount", "0"))?.toFixed(2)}</td>
                  <td>
                    {get(
                      py,
                      `${
                        hideDialog?.refund ? "payment." : ""
                      }meta.pg_reference_id`,
                      "-"
                    )}
                  </td>
                  <td>
                    {get(
                      py,
                      `${hideDialog?.refund ? "payment." : ""}referenceId`,
                      "-"
                    )}
                  </td>
                  <td>{get(py, "status", "-")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Dialog>
      </div>
      <div>
        <Dialog
          hidden={!!hideDialogBox}
          onDismiss={() => setHideDialogBox({})}
          dialogContentProps={dialogContentPropsErrorReason}
          modalProps={modelProps}
        >
          <h2 sx={{ marginTop: "5px", color: "#0078D4" }}>
            Payment Error Reason
          </h2>
          <p />
          <p>
            {(() => {
              if (
                responseData?.payment_error_reason === "PG_LATE_AUTH_TIMEOUT"
              ) {
                return <p>Payment failed due to late authentication</p>;
              }
              if (responseData?.payment_error_reason === null) {
                return <p>-</p>;
              }
              return (
                <p sx={{ fontSize: "15px" }}>
                  {responseData?.payment_error_reason}
                </p>
              );
            })()}
          </p>
        </Dialog>
      </div>
      <div>
        <Dialog
          isOpen={!!showDialog}
          type={DialogType.close}
          onDismiss={() => setShowDialog(null)}
          closeButtonAriaLabel="Close"
          styles={appsDialogStyles.medium}
          isBlocking
        >
          <h2 sx={{ color: "#0078d4" }}>Receipts</h2>
          <table cellSpacing={0} sx={{ width: "100%" }}>
            <thead
              sx={{
                display: "contents",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0",
                svg: {
                  mt: "8px",
                  mr: "4px",
                },
              }}
            >
              <tr>
                {headings.map((item) => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {showDialog &&
                showDialog.data &&
                orderBy(
                  showDialog.data,
                  (a) => new Date(a.createdDate),
                  "desc"
                ).map((d) => {
                  return (
                    <>
                      <tr
                        sx={{
                          td: {
                            padding: "8px",
                            whiteSpace: "nowrap",
                            fontSize: "0.675rem",
                            borderBottom: "1px solid #dfdfff",
                          },
                          "&:hover": {
                            background: "#dfdfff42",
                          },
                        }}
                      >
                        <td>
                          {get(d, "createdDate", "")
                            ? getFormattedDate(
                                get(d, "createdDate", "-"),
                                "Date-Time"
                              )
                            : "-"}
                        </td>
                        <td>{d.receiptNumber}</td>
                        <td>
                          <IconButton
                            iconProps={{ iconName: "Download" }}
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              downloadReceipt(
                                d.id,
                                `receipt-${showDialog.id}`,
                                "/download/receipt/"
                              )
                            }
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
          <div sx={{ marginTop: "20px", float: "right", marginBottom: "30px" }}>
            <DefaultButton
              onClick={() => setShowDialog(null)}
              sx={{ backgroundColor: "#dfdfff" }}
            >
              Cancel
            </DefaultButton>
          </div>
        </Dialog>
      </div>
    </>
  );
};

PaymentTable.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.any).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default PaymentTable;
