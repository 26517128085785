/** @jsx jsx */
import { jsx, Flex, Container } from "theme-ui";
import { useCallback, useEffect, useState, useMemo } from "react";
import { validatePNR } from "@heartfulnessinstitute/react-hfn-forms/dist/validations";
import useFetchOmsApi from "gatsby-plugin-hfn-profile/components/Hooks/useFetchOmsApi";
import { DefaultButton } from "office-ui-fabric-react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import PnrHeader from "../../components/PnrHeader";
import authRestriction from "../../utils/authRestriction";
import PaymentTable from "../../components/PaymentTable";

const PnrSearch = ({ location }) => {
  const [pnr, setPnr] = useState("");
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState("");
  const [isFetching, setFetching] = useState(false);
  const goBack = () =>
    navigate(pnr ? `/pnr-search/pnr/?pnr=${pnr}` : "/pnr-search");

  useMemo(() => {
    const pd = new URLSearchParams(location.search).get("pnr");
    setPnr(pd);
  }, [location]);

  const { fetchOmsApi } = useFetchOmsApi();
  const eventName = useMemo(
    () =>
      orders.length > 0
        ? orders?.[0]?.items?.[0]?.details?.event_title || ""
        : "",

    [orders]
  );
  const ordersLength = useMemo(() => {
    if (orders && orders.length > 0) {
      return orders.length;
    }
    return 0;
  }, [orders]);

  useEffect(() => {
    if (pnr && validatePNR(pnr)) {
      setFetching(true);
      fetchOmsApi({
        api: `/order-by-pnr/${pnr}`,
      })
        .then((res) => {
          setOrders(res?.order || []);
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }
  }, [pnr, fetchOmsApi]);

  const validatePnr = useCallback(
    (val) => {
      if (!validatePNR(val)) {
        setError("Enter a valid PNR");
      } else {
        setError("");
      }
      setPnr(val);
      return null;
    },
    [setError, setPnr]
  );

  return (
    <div>
      <PnrHeader />
      <main>
        <Container py={2} px={4}>
          <Flex
            sx={{
              justifyContent: "space-between",
              flexDirection: ["column", null, "row"],
            }}
          >
            <h4 sx={{ pb: 1, m: 0, mb: "0.25rem" }}>
              Payment report - {eventName}
            </h4>
            <DefaultButton sx={{ width: "200px" }} onClick={() => goBack(pnr)}>
              Go Back
            </DefaultButton>
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: ["none", null, "center"],
                position: "relative",
              }}
            >
              <input
                sx={{
                  outline: "none",
                  borderRadius: "5px",
                  border: "1px solid grey",
                  padding: "0.25rem 1rem",
                  textIndent: "1rem",
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  position: "relative",
                  "&::before": {
                    position: "absolute",
                    content: "''",
                    top: 0,
                    background: "red",
                    width: "100px",
                    height: "100px",
                  },
                }}
                name="search"
                type="text"
                value={pnr}
                onChange={(e) => validatePnr(e.target.value)}
                placeholder="search PNR"
              />
              <span
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "8px",
                }}
              >
                &#128269;
              </span>
              {error && error.includes("PNR") && <small>{error}</small>}
            </div>
          </Flex>
          <p sx={{ m: 0, fontSize: "0.875rem" }}>
            Showing {ordersLength ? 1 : 0} - {ordersLength} of {ordersLength}{" "}
            results
          </p>
        </Container>
        <PaymentTable orders={orders} isFetching={isFetching} />
      </main>
    </div>
  );
};

PnrSearch.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

PnrSearch.defaultProps = {
  location: { search: "" },
};

export default authRestriction(PnrSearch);
